<template>
  <div>
    <v-card>
      <v-card-title class="pt-0 pb-0">
        <span class="title-page">REQUISIÇÃO DE PESSOAL</span>

        <v-spacer />

        <v-text-field
          v-model="filterData"
          class="mt-6"
          label="Buscar por requisitante, função, status"
          outlined
          dense
          @keyup.enter="findFilterResult"
        />

        <v-btn
          color="info"
          class="ml-4"
          outlined
          @click="handleOpenModalRequest"
        >
          <v-icon
            size="30"
            class="me-2"
          >
            {{ icons.mdiPlaylistPlus }}
          </v-icon>
          NOVA REQUISIÇÃO
        </v-btn>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="listOfFilteredItems"
        :loading="isLoadingData"
        loading-text="Carregando dados..."
      >
        <template v-slot:no-data>
          <span>Sem informações para exibir</span>
        </template>

        <template v-slot:item.action="{ item }">
          <v-icon
            medium
            class="me-2"
            color="warning"
            @click="handleOpenModalRequestEdit(item)"
          >
            {{ icons.mdiEye }}
          </v-icon>
        </template>

        <template v-slot:item.status="{ item }">
          <v-alert
            class="badge"
            :color="getStatusColor(item)"
            text
          >
            {{ item.status ? item.status : '' }}
          </v-alert>
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ dateFormat(item.created_at) }}
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="showModalRequest"
      width="600px"
    >
      <ModalPersonRequest
        @close="showModalRequest = false, dataModalView.id = ''"
        @updatedTable="updatedTable"
      ></ModalPersonRequest>
    </v-dialog>

    <v-dialog
      v-model="showModalRequestEdit"
      width="600px"
    >
      <ModalPersonRequestEdit
        :key="dataModalView.id"
        :data="dataModalView"
        :close-modal-edit="handleCloseModalEdit"
        :profile="userProfile"
        @close="showModalRequestEdit = false"
        @updatedTable="updatedTable"
      ></ModalPersonRequestEdit>
    </v-dialog>

    <v-dialog
      v-model="showModalRD"
      width="700px"
    >
      <ModalRD
        :key="dataModalView.id"
        :data="dataModalView"
        :close-modal-rd="handleCloseModalRD"
        :profile="userProfile"
        @close="showModalRD = false"
        @updatedTable="updatedTable"
      ></ModalRD>
    </v-dialog>

    <v-dialog
      v-model="showModalWaitingLeader"
      width="700px"
    >
      <ModalWaitingLeader
        :key="dataModalView.id"
        :data="dataModalView"
        :close-modal-rd="handleCloseModalWaitingLeader"
        :profile="userProfile"
        @close="showModalWaitingLeader = false"
        @updatedTable="updatedTable"
      ></ModalWaitingLeader>
    </v-dialog>

    <v-dialog
      v-model="showModalRequestCanceled"
      width="700px"
    >
      <ModalRequestCanceled
        :key="dataModalView.id"
        :data="dataModalView"
        @close="showModalRequestCanceled = false"
        @updatedTable="updatedTable"
      ></ModalRequestCanceled>
    </v-dialog>

    <v-dialog
      v-model="showModalRequestWaitingCancellation"
      width="700px"
    >
      <ModalRequestWaitingCancellation
        :key="dataModalView.id"
        :data="dataModalView"
        @close="showModalRequestWaitingCancellation = false"
        @updatedTable="updatedTable"
      ></ModalRequestWaitingCancellation>
    </v-dialog>

    <v-dialog
      v-model="showModalStadBy"
      width="700px"
    >
      <ModalStadBy
        :key="dataModalView.id"
        :data="dataModalView"
        @close="showModalStadBy = false"
        @updatedTable="updatedTable"
      ></ModalStadBy>
    </v-dialog>
  </div>
</template>

<script>
import axiosIns from '@/plugins/axios'
import {
  mdiClipboardText, mdiEye, mdiFilterPlusOutline, mdiFilterRemoveOutline, mdiPlaylistPlus,
} from '@mdi/js'
import localStorageSlim from 'localstorage-slim'
import ModalPersonRequest from './ModalPersonRequest.vue'
import ModalPersonRequestEdit from './ModalPersonRequestEdit.vue'
import ModalRD from './ModalRD.vue'
import ModalRequestCanceled from './ModalRequestCanceled.vue'
import ModalRequestWaitingCancellation from './ModalRequestWaitingCancellation.vue'
import ModalStadBy from './ModalStadBy.vue'
import ModalWaitingLeader from './ModalWaitingLeader.vue'

export default {
  components: {
    ModalWaitingLeader,
    ModalPersonRequest,
    ModalPersonRequestEdit,
    ModalRD,
    ModalRequestCanceled,
    ModalRequestWaitingCancellation,
    ModalStadBy,
  },

  data() {
    return {
      filterData: '',
      showModalWaitingLeader: false,
      showModalStadBy: false,
      showModalRequestWaitingCancellation: false,
      showModalRD: false,
      showModalRequestCanceled: false,
      dataModalView: {},
      showModalRequest: false,
      showModalRequestEdit: false,

      icons: {
        mdiEye,
        mdiPlaylistPlus,
        mdiClipboardText,
        mdiFilterPlusOutline,
        mdiFilterRemoveOutline,
      },

      listOfFilteredItems: [],
      isLoadingData: false,
      itemsTable: [],

      headers: [
        {
          text: 'REQUISITANTE',
          value: 'creator.name',
          sortable: false,
          align: 'left',
        },
        {
          text: 'LOJA',
          value: 'creator.company.city',
          sortable: false,
          align: 'center',
        },
        {
          text: 'DATA',
          value: 'created_at',
          sortable: true,
          align: 'center',
        },
        {
          text: 'SETOR',
          value: 'creator.occupation_sector.sector.name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'FUNÇÃO REQUISITADA',
          value: 'occupation.name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'STATUS',
          value: 'status',
          sortable: true,
          align: 'center',
        },
        {
          text: 'VISUALIZAR',
          value: 'action',
          sortable: false,
          align: 'center',
        },
      ],

      userProfile: '',
    }
  },

  watch: {
    filterData() {
      this.findFilterResult()
    },
  },

  async created() {
    this.handleGetData()

    const userProfiles = localStorageSlim.get('userProfile', { decrypt: true })

    this.userProfile = userProfiles.profile.profile
  },

  methods: {
    handleCloseModalRD() {
      this.showModalRD = false
    },

    handleCloseModalWaitingLeader() {
      this.showModalWaitingLeader = false
    },

    handleCloseModalEdit() {
      this.showModalRequestEdit = false
    },

    async handleGetData() {
      this.isLoadingData = true

      try {
        const response = await axiosIns.get('/api/v1/personnel_request/employee_request/index')

        this.listOfFilteredItems = response.data.data
        this.itemsTable = response.data.data
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoadingData = false
      }
    },

    dateFormat(date) {
      const dateSliced = date.slice(0, 10)

      const hour = date.slice(11, 19)

      const dateDivided = dateSliced.split('-')

      const dateFormatted = `${dateDivided[2]}/${dateDivided[1]}/${dateDivided[0]} - ${hour}`

      return dateFormatted
    },

    getStatusColor(item = { status: '' }) {
      const { status } = item

      const statusColorMap = {
        'AGUARDANDO RH': 'yellowGold',
        'AGUARDANDO DP': 'blue',
        'AGUARDANDO RD': 'info',
        'AGUARDANDO DIRETORIA': 'gray',
        'AGUARDANDO ENCERRAMENTO': 'greenWeak',
        'EM ANDAMENTO': 'orange',
        'STAND-BY': 'purple',
        CONCLUÍDO: 'success',
        STANDBY: 'purple',
        CANCELADO: 'error',
      }

      return statusColorMap[status] ?? ''
    },

    handleOpenModalRequest() {
      this.showModalRequest = true
    },

    handleOpenModalRequestEdit(data) {
      const { status } = data

      this.dataModalView = data

      const statusOpenModal = [
        'AGUARDANDO RD',
        'AGUARDANDO DIRETORIA',
        'EM ANDAMENTO',
        'AGUARDANDO DP',
        'AGUARDANDO ENCERRAMENTO',
        'CONCLUÍDO',
      ]

      if (statusOpenModal.includes(status)) {
        this.showModalRD = true
      }

      if (status === 'AGUARDANDO RH') {
        this.showModalRequestEdit = true
      }

      if (status === 'CANCELADO') {
        this.showModalRequestCanceled = true
      }

      if (status === 'AGUARDANDO CANCELAMENTO') {
        this.showModalRequestWaitingCancellation = true
      }

      if (status === 'STAND-BY') {
        this.showModalStadBy = true
      }

      if (status === 'AGUARDANDO LÍDER') {
        this.showModalWaitingLeader = true
      }
    },

    updatedTable() {
      this.handleGetData()
    },

    findFilterResult() {
      const { itemsTable, filterData } = this
      const filterDataLower = filterData.toLowerCase()

      this.listOfFilteredItems = itemsTable.filter(
        item => {
          const { name: nameCreator } = item.creator
          const { name } = item.occupation
          const status = item.status.toLowerCase()
          const nameLowed = name.toLowerCase()
          const creatorLowed = nameCreator.toLowerCase()

          return status.includes(filterDataLower) || nameLowed.includes(filterDataLower) || creatorLowed.includes(filterDataLower)
        },
      )
    },
  },
}
</script>

<style lang="scss">
  .badge {
  margin-top: 16px;
  font-size: 13px;
  height: 40px;
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-page{
  font-size: 15px;
}
</style>
