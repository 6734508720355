<template>
  <div>
    <v-card class="px-9 py-9">
      <div class="head-modal">
        <div></div>

        <span>Enviar para RH</span>

        <v-icon
          medium
          class="me-2"
          @click="handleCloseModalSendRD"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>

      <div class="py-4">
        <span>👋 Olá, antes de enviar para o RH, preencha os campos abaixo!</span>
      </div>

      <div class="separator">
        <div
          class="label"
        >
          <span>Informações do efetivo</span>
        </div>
      </div>

      <div class="line mt-6">
        <v-dialog
          ref="dialogDateTime"
          v-model="showCalendar"
          :return-value.sync="inconsistencyDate"
          width="400px"
          persistent
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedInconsistencyDate"
              :append-icon="icons.mdiCalendar"
              :rules="[rules.required]"
              label="Data de Admissão"
              readonly
              outlined
              dense
              v-bind="attrs"
              :disabled="toggleSwitch"
              v-on="on"
            />
          </template>

          <v-date-picker
            v-model="inconsistencyDate"
            width="400px"
            locale="pt-BR"
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="showCalendar = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialogDateTime.save(inconsistencyDate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </div>

      <div
        class="stand-by"
      >
        <v-switch
          v-model="toggleSwitch"
          color="purple"
        />

        <p>Reprovado no exame admissional</p>
      </div>

      <div class="container-center">
        <v-btn
          color="success"
          class="ml-4"
          @click="handleSendRH"
        >
          <span v-if="!loadingSendDP">ENVIAR PARA RH</span>

          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card>
  </div>
</template>
<script>

import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import utilsMixin from '@/plugins/utilsMixin'
import { mdiCalendar, mdiClose } from '@mdi/js'

export default {
  components: {
  },

  mixins: [formatters, utilsMixin],

  props: {
    data: {
      type: [String, Object],
      required: true,
    },
  },

  data() {
    return {
      toggleSwitch: false,
      inconsistencyDate: '',
      showCalendar: false,
      loadingSendDP: false,
      icons: {
        mdiClose,
        mdiCalendar,
      },
      listClass: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11'],
      listLevels: ['1', '2', '3', '4', '5', '6', '7', '8'],
      salary: '',
      className: '',
      level: '',
      atr: '',
      comments: '',
      hiredPersonName: '',
      hiredPersonCpf: '',
      expectedStartDate: '',
    }
  },

  computed: {
    computedInconsistencyDate: {
      get() {
        return (this.inconsistencyDate)
      },

      set() {},
    },
  },

  created() {
    // console.log('ModalSendDP: ', this.data)
  },

  methods: {
    handleCloseModalSendRD() {
      this.closeModal()
    },

    async handleSendRH() {
      this.loadingSendDP = true

      const body = {
        status: 'AGUARDANDO ENCERRAMENTO',
        hired_date: this.toggleSwitch ? '' : this.computedInconsistencyDate,
        was_person_reproved: this.toggleSwitch,
      }

      try {
        await axiosIns.put(`api/v1/personnel_request/employee_request/update/${this.data.id}`, body)

        this.$swal({
          icon: 'success',
          title: 'Requisição enviada!',
          text: 'A requisição foi enviada para o RH!',
          showConfirmButton: false,
          timer: 3000,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao enviar dados!',
          showConfirmButton: false,
          text: error,
          timer: 3000,
        })
      } finally {
        this.loadingSendDP = false

        this.updatedTable()
        this.closeModal()
      }
    },

    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },
  },
}
</script>
<style scoped>
.head-modal{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.separator{
  position: relative;
  border-top: 1px solid #cac9cd96;
  margin-top: 20px;
}

.separator .label{
  top: -22px;
  left: 50px;
  position: absolute;
  z-index: 100;
  background: #312d4b;
  padding: 8px;
}

.container-center{
  display: flex;
  justify-content: center;
}

.line{
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  margin-top: 30px;
}

.stand-by{
  display: flex;
  align-items: center;

  p{
    margin-top: 12px;
  }
}
</style>
