import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,{staticClass:"px-9 py-9"},[_c('div',{staticClass:"head-modal"},[_c('div'),_c('span',[_vm._v("Enviar para o líder")]),_c(VIcon,{staticClass:"me-2",attrs:{"medium":""},on:{"click":_vm.handleCloseModalSendRD}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1),_c('div',{staticClass:"py-4"},[_c('span',[_vm._v("👋 Olá, antes de enviar para o líder, preencha os campos abaixo!")])]),_c('div',{staticClass:"separator"},[_c('div',{staticClass:"label"},[_c('span',[_vm._v("informações do aprovado")])])]),_c(VTextField,{staticClass:"mt-6",attrs:{"label":"Nome","outlined":"","dense":""},model:{value:(_vm.hiredPersonName),callback:function ($$v) {_vm.hiredPersonName=$$v},expression:"hiredPersonName"}}),_c('div',{staticClass:"line mt-0"},[_c(VTextField,{attrs:{"label":"CPF","type":"number","outlined":"","dense":""},model:{value:(_vm.hiredPersonCpf),callback:function ($$v) {_vm.hiredPersonCpf=$$v},expression:"hiredPersonCpf"}}),_c(VDialog,{ref:"dialogDateTime",attrs:{"return-value":_vm.inconsistencyDate,"width":"400px","persistent":""},on:{"update:returnValue":function($event){_vm.inconsistencyDate=$event},"update:return-value":function($event){_vm.inconsistencyDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"append-icon":_vm.icons.mdiCalendar,"rules":[_vm.rules.required],"label":"Previsão de Admissão","readonly":"","outlined":"","dense":""},model:{value:(_vm.computedInconsistencyDate),callback:function ($$v) {_vm.computedInconsistencyDate=$$v},expression:"computedInconsistencyDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.showCalendar),callback:function ($$v) {_vm.showCalendar=$$v},expression:"showCalendar"}},[_c(VDatePicker,{attrs:{"width":"400px","locale":"pt-BR","scrollable":""},model:{value:(_vm.inconsistencyDate),callback:function ($$v) {_vm.inconsistencyDate=$$v},expression:"inconsistencyDate"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.showCalendar = false}}},[_vm._v(" Cancelar ")]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialogDateTime.save(_vm.inconsistencyDate)}}},[_vm._v(" OK ")])],1)],1)],1),_c(VFileInput,{ref:"fileInput",attrs:{"prepend-inner-icon":_vm.icons.mdiPaperclip,"prepend-icon":"","label":"Anexar arquivo","outlined":"","multiple":"","chips":"","dense":""},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}}),_c('span',{staticClass:"container-caption"},[_vm._v(" * O tamanho do arquivo não pode exceder o limite máximo de 10MB. ")]),_c('div',{staticClass:"separator"},[_c('div',{staticClass:"label"},[_c('span',[_vm._v("Outras informações")])])]),_c(VTextarea,{staticClass:"mt-6",attrs:{"label":"Parecer do RH","rules":[_vm.rules.required],"outlined":""},model:{value:(_vm.rhOpinion),callback:function ($$v) {_vm.rhOpinion=$$v},expression:"rhOpinion"}}),_c('div',{staticClass:"container-center"},[_c(VBtn,{staticClass:"ml-4",attrs:{"color":"success"},on:{"click":_vm.handleSendDP}},[(!_vm.loadingSendDP)?_c('span',[_vm._v("ENVIAR PARA O LÍDER")]):_c(VProgressCircular,{attrs:{"color":"white","indeterminate":""}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }