<template>
  <div>
    <v-card class="px-9 py-9">
      <div class="head-modal">
        <div></div>

        <span>Requisição de Pessoal</span>

        <v-icon
          medium
          class="me-2"
          @click="handleCloseModal"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>

      <div class="py-4">
        <span>👋 Olá, para requisitar, basta preencher os campos abaixo!</span>
      </div>

      <div class="separator">
        <div
          class="label"
        >
          <span>Justificativa</span>
        </div>
      </div>

      <v-form ref="form">
        <div class="line">
          <v-autocomplete
            v-model="typeContractValue"
            class=""
            :items="listTypeContract"
            :rules="[rules.required]"
            label="Tipo de Contrato"
            outlined
            dense
          />

          <v-autocomplete
            v-model="reasonRequestValue"
            class=""
            :items="listReasonRequest"
            :rules="[rules.required]"
            label="Motivo da Requisição"
            outlined
            dense
          />
        </div>

        <v-autocomplete
          v-model="replacedEmployeeValue"
          class=""
          :items="listEmployees"
          label="Funcionário Substituído"
          item-text="name"
          item-value="id"
          outlined
          dense
          :disabled="reasonRequestValue !== 'Substituição'"
          :rules="[reasonRequestValue === 'Substituição' ? rules.required : null]"
        />

        <v-textarea
          v-model="reasonDescriptionValue"
          :rules="[rules.required]"
          label="Descrição do motivo"
          outlined
        />

        <div class="separator">
          <div
            class="label"
          >
            <span>Dados da vaga</span>
          </div>
        </div>

        <v-autocomplete
          v-model="functionValue"
          class="mt-5"
          :items="listFunctions"
          :rules="[rules.required]"
          label="Função"
          item-text="occupation.name"
          item-value="occupation.id"
          outlined
          dense
        />

        <v-autocomplete
          v-model="sectorValue"
          class=""
          :items="listSectors"
          :rules="[rules.required]"
          label="Setor"
          item-text="name"
          item-value="id"
          outlined
          dense
        />

        <div class="line mt-0">
          <v-text-field
            v-model="ageValue"
            type="number"
            class=""
            label="Idade"
            outlined
            dense
          />

          <v-autocomplete
            v-model="genderValue"
            class=""
            :items="listGenders"
            label="Sexo"
            outlined
            dense
          />
          <v-autocomplete
            v-model="educationLevelValue"
            class=""
            :items="listEducation"
            :rules="[rules.required]"
            label="Nível de escolaridade"
            outlined
            dense
          />
        </div>

        <v-textarea
          v-model="requirementsVacancyValue"
          label="Requisitos da Vaga"
          :rules="[rules.required]"
          outlined
        />

        <v-textarea
          v-model="mainActivitiesValue"
          label="Principais Atividades"
          :rules="[rules.required]"
          outlined
        />
      </v-form>

      <div class="container-center">
        <v-btn
          color="info"
          class="ml-4"
          :disabled="loadingSubmitRequest"
          @click="handleSubmit"
        >
          <span v-if="!loadingSubmitRequest">REQUISITAR</span>

          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card>
  </div>
</template>
<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import { mdiClose } from '@mdi/js'
import localStorageSlim from 'localstorage-slim'

export default {
  components: {
  },
  mixins: [formatters],

  data() {
    return {
      disabledInputEmployee: false,
      loadingSubmitRequest: false,

      listEmployees: [],
      listFunctions: [],
      listSectors: [],

      listGenders: ['MASCULINO', 'FEMININO', 'NÃO INFORMAR'],
      listEducation: ['FUNDAMENTAL', 'MÉDIO', 'SUPERIOR', 'SUPERIOR INCOMPLETO'],
      typeContractValue: '',
      reasonRequestValue: '',
      replacedEmployeeValue: '',
      reasonDescriptionValue: '',
      functionValue: '',
      sectorValue: '',
      ageValue: '',
      educationLevelValue: '',
      genderValue: '',
      requirementsVacancyValue: '',
      mainActivitiesValue: '',

      icons: {
        mdiClose,
      },

      listTypeContract: [
        'Contrato Efetivo',
        'Contrato Temporário',
        'Contrato por Tempo Determinado',
        'Estagiário',
        'Aprendiz',
      ],

      listReasonRequest: [
        'Aumento de Quadro',
        'Promoção',
        'Substituição',
      ],
    }
  },

  created() {
    this.listEmployees = localStorageSlim.get('listEmployees', { decrypt: true })

    this.getData()
  },

  methods: {
    async getData() {
      try {
        const [occupations, sectors] = await Promise.all([
          axiosIns.get('api/v1/records/occupation/index'),
          axiosIns.get('api/v1/records/sector/index'),
        ])

        this.listFunctions = occupations.data.data
        this.listSectors = sectors.data.data
      } catch (error) {
        console.error(error)
      }
    },

    handleCloseModal() {
      // this.typeContractValue = ''
      // this.reasonRequestValue = ''
      // this.functionValue = ''
      // this.sectorValue = ''
      // this.ageValue = ''
      // this.genderValue = ''
      // this.educationLevelValue = ''
      // this.requirementsVacancyValue = ''
      // this.mainActivitiesValue = ''
      // this.replacedEmployeeValue = ''
      // this.reasonDescriptionValue = ''

      // this.$refs.form.reset()

      this.closeModal()
    },

    async handleSubmit() {
      const formIsValid = this.$refs.form.validate()

      if (!formIsValid) return

      const body = {
        contract_types: this.typeContractValue.toUpperCase(),
        request_reason: this.reasonRequestValue.toUpperCase(),
        occupation_id: this.functionValue,
        sector_id: this.sectorValue,
        age: Number(this.ageValue),
        sex: this.genderValue,
        education_level: this.educationLevelValue,
        vacancy_requirements: this.requirementsVacancyValue,
        main_activities: this.mainActivitiesValue,
        status: 'AGUARDANDO RH',
        substitute_user_id: this.replacedEmployeeValue,
        request_reason_description: this.reasonDescriptionValue,
      }

      try {
        this.loadingSubmitRequest = true
        await axiosIns.post('api/v1/personnel_request/employee_request/store', body)

        this.$swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Requisição feita com sucesso!',
          showConfirmButton: false,
          timer: 3000,
        })
      } catch (error) {
        console.error({ body, error })

        this.$swal({
          icon: 'error',
          title: 'Erro ao enviar dados!',
          showConfirmButton: false,
          text: error,
          timer: 3000,
        })
      } finally {
        this.loadingSubmitRequest = false
        this.updatedTable()
        this.closeModal()

        this.$refs.form.reset()
      }
    },
  },
}
</script>
<style scoped>
.head-modal{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.separator{
  position: relative;
  border-top: 1px solid #cac9cd96;
  margin-top: 20px;
}

.separator .label{
  top: -22px;
  left: 50px;
  position: absolute;
  z-index: 100;
  background: #312d4b;
  padding: 8px;
}

.line{
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  margin-top: 30px;
}

.container-center{
  display: flex;
  justify-content: center;
}
</style>
