<template>
  <div>
    <v-card class="px-9 py-9">
      <div class="head-modal">
        <div></div>

        <span>Requisição de Pessoal</span>

        <v-icon
          medium
          class="me-2"
          @click="closeModal"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>

      <v-tabs
        v-model="tab"
        show-arrows
        grow
        stacked
        class="tabs-just"
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.icon"
        >
          <v-icon
            size="20"
            class="me-3"
          >
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <div class="separator">
            <div
              class="label"
            >
              <span>Justificativa</span>
            </div>
          </div>

          <div class="line">
            <v-text-field
              v-model="data.contract_types"
              class=""
              label="Tipo de Contrato"
              outlined
              dense
              disabled
            />

            <v-text-field
              v-model="data.request_reason"
              class=""
              label="Motivo da Requisição"
              outlined
              dense
              disabled
            />
          </div>

          <v-text-field
            v-if="Boolean(data.substitute_user)"
            class=""
            label="Funcionário Substituído"
            outlined
            dense
            disabled
          />

          <v-text-field
            v-else
            v-model="substituteUserName"
            class=""
            label="Funcionário Substituído"
            outlined
            dense
            disabled
          />

          <v-textarea
            v-model="data.request_reason_description"
            label="Descrição do motivo"
            outlined
            disabled
          />

          <div class="separator">
            <div
              class="label"
            >
              <span>Dados da vaga</span>
            </div>
          </div>

          <v-text-field
            v-model="data.occupation.name"
            class="mt-5"
            label="Função"
            outlined
            dense
            disabled
          />

          <v-text-field
            v-model="data.sector.name"
            class=""
            label="Setor"
            outlined
            dense
            disabled
          />

          <div class="line mt-0">
            <v-text-field
              v-model="data.age"
              class=""
              label="Idade"
              outlined
              dense
              disabled
            />

            <v-text-field
              v-model="data.sex"
              class=""
              label="Sexo"
              outlined
              dense
              disabled
            />
            <v-text-field
              v-model="data.education_level"
              class=""
              label="Nível de escolaridade"
              outlined
              dense
              disabled
            />
          </div>

          <v-textarea
            v-model="data.vacancy_requirements"
            label="Requisitos da Vaga"
            outlined
            disabled
          />

          <v-textarea
            v-model="data.main_activities"
            label="Principais Atividades"
            outlined
            disabled
          />
        </v-tab-item>
        <v-tab-item>
          <div class="separator">
            <div
              class="label"
            >
              <span>Dados da Função</span>
            </div>
          </div>
          <v-text-field
            v-model="data.occupation.name"
            class="mt-5"
            label="Função"
            outlined
            dense
            disabled
          />

          <v-text-field
            v-model="data.sector.name"
            class=""
            label="Setor"
            outlined
            dense
            disabled
          />

          <div class="line mt-6">
            <v-text-field
              v-model="data.age"
              class=""
              label="Idade"
              outlined
              dense
              disabled
            />

            <v-text-field
              v-model="data.sex"
              class=""
              label="Sexo"
              outlined
              dense
              disabled
            />
            <v-text-field
              v-model="data.education_level"
              class=""
              label="Nível de escolaridade"
              outlined
              dense
              disabled
            />
            <v-text-field
              v-model="data.education_level"
              class=""
              label="Nível de escolaridade"
              outlined
              dense
              disabled
            />
          </div>

          <v-textarea
            v-model="data.human_resource_observation"
            label="Observações"
            outlined
            disabled
          />

          <div
            class="validations"
          >
            <span>validações</span>

            <div
              v-if="data.manager_approved_at"
              class="infos"
            >
              <div class="image">
                <v-avatar
                  size="40px"
                  color="primary"
                  class="v-avatar-light-bg primary--text"
                >
                  <v-img src="../../../../../assets/avatar-rd.svg"></v-img>
                </v-avatar>
              </div>

              <div class="infos-aproved">
                <p>RD</p>

                <div class="infos-text">
                  <small>{{ data.manager_approved_at }}</small>

                  <div class="tag">
                    {{ data.status === 'AGUARDANDO ENCERRAMENTO' && !data.manager_approved_at ? 'REPROVADA' : 'APROVADA' }}
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="data.director_approved_at"
              class="infos"
            >
              <div class="image">
                <v-avatar
                  size="40px"
                  color="primary"
                  class="v-avatar-light-bg primary--text"
                >
                  <v-img src="../../../../../assets/avatar-director.svg"></v-img>
                </v-avatar>
              </div>

              <div class="infos-aproved">
                <p>Diretor</p>

                <div class="infos-text">
                  <small>{{ data.director_approved_at }}</small>

                  <div class="tag">
                    {{ data.status === 'AGUARDANDO ENCERRAMENTO' && !data.director_approved_at ? 'REPROVADA' : 'APROVADA' }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="data.status === 'AGUARDANDO RD' || data.status === 'AGUARDANDO DIRETORIA'"
            class="container-center"
          >
            <v-btn
              color="error"
              class="ml-4"
              @click="handleOpenModalCancel"
            >
              <div v-if="!loadingCancel">
                <v-icon
                  medium
                  class="me-2"
                >
                  {{ icons.mdiThumbDownOutline }}
                </v-icon>
                <span>RECUSAR</span>
              </div>

              <v-progress-circular
                v-else
                color="white"
                indeterminate
              >
              </v-progress-circular>
            </v-btn>

            <v-btn
              color="success"
              class="ml-4"
              @click="handleSendDirector"
            >
              <div v-if="!loadingSubmitRequestSendDirector">
                <v-icon
                  medium
                  class="me-2"
                >
                  {{ icons.mdiThumbUpOutline }}
                </v-icon>
                <span>APROVAR</span>
              </div>

              <v-progress-circular
                v-else
                color="white"
                indeterminate
              >
              </v-progress-circular>
            </v-btn>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div class="separator mt-6">
            <div
              class="label"
            >
              <span>Informações do processo</span>
            </div>
          </div>

          <div
            v-if="loadingHistory"
            class="progress-container-loading"
          >
            <v-progress-circular
              color="white"
              indeterminate
            >
            </v-progress-circular>
          </div>

          <div
            v-else
            class="progress-container"
          >
            <span>Status do Processo</span>

            <div
              v-for="(item, index) in dataHistory"
              :key="item.id"
              class="container-item"
            >
              <div class="container-icon">
                <img
                  v-if="index !== lastNonCompletedIndex || hasConcluded"
                  src="../../../../../assets/rightIcon.svg"
                  width="20"
                >
                <img
                  v-else
                  src="../../../../../assets/waitingIcon.svg"
                  width="20"
                >

                <small :class="getStatusClass(index)">{{ hasConcluded ? 'CONCLUÍDO' : (index === lastNonCompletedIndex ? 'STATUS ATUAL' : 'CONCLUÍDO') }}</small>
              </div>

              <div class="item-time-line">
                <p>{{ item.new_status }}</p>
                <p>{{ item.updated_at }}</p>
              </div>
            </div>
          </div>
          <div
            class="stand-by"
          >
            <v-switch
              v-model="toggleSwitch"
              color="purple"
            />

            <p>Stand by</p>

            <v-progress-circular
              v-if="loadingStadBy"
              color="gray"
              :size="15"
              indeterminate
              class="ml-2"
              width="3"
            >
            </v-progress-circular>
          </div>

          <div
            class="footer"
          >
            <v-btn
              color="error"
              class="ml-4"
              @click="showModalCancel = true"
            >
              <div v-if="!loadingCancel">
                <span>Cancelar</span>
              </div>

              <v-progress-circular
                v-else
                color="white"
                indeterminate
              >
              </v-progress-circular>
            </v-btn>

            <v-btn
              v-if="data.status !== 'STAND-BY'"

              color="success"
              class="ml-4"
              @click="handleOpenModalSendDP"
            >
              <div>
                <span>Enviar para o DP</span>
              </div>
            </v-btn>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-dialog
      v-model="showModalDP"
      width="600px"
    >
      <ModalSendDP
        :data="data"
        @close="showModalDP = false"
      ></ModalSendDP>
    </v-dialog>

    <v-dialog
      v-model="showModalRH"
      width="600px"
    >
      <ModalSendRH
        :data="data"
        @close="showModalRH = false"
      ></ModalSendRH>
    </v-dialog>

    <v-dialog
      v-model="showModalCancel"
      width="540px"
    >
      <ModalCancel
        :data="dataCancel"
        @close="showModalCancel = false"
        @updatedTable="handleUpdatedTable"
      ></ModalCancel>
    </v-dialog>
  </div>
</template>
<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import {
  mdiAccountOutline,
  mdiAlertCircleOutline,
  mdiCalendarBlankOutline,
  mdiCheckCircleOutline,
  mdiClose,
  mdiThumbDownOutline,
  mdiThumbUpOutline,
} from '@mdi/js'
import ModalCancel from './ModalCancel.vue'
import ModalSendDP from './ModalSendDP.vue'
import ModalSendRH from './ModalSendRH.vue'

export default {
  components: {
    ModalSendDP,
    ModalSendRH,
    ModalCancel,
  },
  mixins: [formatters],
  props: {
    data: {
      type: [String, Object],
      required: true,
    },
  },

  data() {
    return {
      loadingStadBy: false,
      dataCancel: {},
      showModalCancel: false,
      hasConcluded: false,
      dataHistory: [],
      loadingFinish: false,
      showModalRH: false,
      currentDate: '',
      imageProfile: '',
      showModalDP: false,
      toggleSwitch: true,
      loadingCancel: false,
      loadingSendRD: false,
      tab: '',
      tabs: [
        { title: 'Requisição', icon: mdiAccountOutline },
        { title: 'Validação', icon: mdiAlertCircleOutline },
        { title: 'Resultados', icon: mdiCheckCircleOutline },
      ],
      disabledInputEmployee: false,
      loadingSubmitRequestSendDirector: false,
      listEmployees: [],
      listFunctions: [],
      listSectors: [],
      listGenders: ['MASCULINO', 'FEMININO', 'NÃO BINÁRIO'],
      listEducation: ['FUNDAMENTAL', 'MÉDIO', 'SUPERIOR', 'SUPERIOR INCOMPLETO'],
      typeContractValue: '',
      reasonRequestValue: '',
      replacedEmployeeValue: '',
      reasonDescriptionValue: '',
      functionValue: '',
      sectorValue: '',
      ageValue: '',
      educationLevelValue: '',
      genderValue: '',
      requirementsVacancyValue: '',
      mainActivitiesValue: '',
      icons: {
        mdiClose,
        mdiAccountOutline,
        mdiThumbDownOutline,
        mdiThumbUpOutline,
        mdiCalendarBlankOutline,
        mdiCheckCircleOutline,
      },
      listTypeContract: [
        'Contrato Efetivo',
        'Contrato Temporário',
        'Contrato por Tempo Determinado',
        'Estagiário',
        'Aprendiz',
      ],
      listReasonRequest: [
        'Aumento de Quadro',
        'Promoção',
        'Substituição',
      ],
      status: 'EM ANDAMENTO',
      validStatuses: ['STAND BY'],
      loadingHistory: false,
    }
  },

  computed: {
    substituteUserName() {
      return this.data.substitute_user ? this.data.substitute_user.name : ''
    },

    isStatusVisible() {
      return this.validStatuses.includes(this.data.status)
    },

    lastNonCompletedIndex() {
      if (this.hasConcluded) {
        return -1
      }

      // eslint-disable-next-line no-plusplus
      for (let i = this.dataHistory.length - 1; i >= 0; i--) {
        if (this.dataHistory[i].new_status !== 'CONCLUÍDO') {
          return i
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.hasConcluded = true // Define como true se encontrar um concluído
      }

      return -1
    },
  },

  watch: {
    async toggleSwitch() {
      this.loadingStadBy = true

      const body = {
        status: this.data.status === 'STAND-BY' ? 'EM ANDAMENTO' : 'STAND-BY',
      }

      try {
        await axiosIns.put(`api/v1/personnel_request/employee_request/update/${this.data.id}`, body)
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao mudar status!',
          showConfirmButton: false,
          text: error,
          timer: 3000,
        })
      } finally {
        this.updatedTable()
        this.closeModal()
        this.loadingStadBy = true
        this.handleUpdatedTable()
      }
    },
  },

  async created() {
    this.toggleSwitch = true

    this.dataCancel = this.data

    this.handleGetCurrentDate()

    this.handleGetStatusHistory()
  },

  methods: {
    handleUpdatedTable() {
      this.updatedTable()
    },

    handleOpenModalCancel() {
      this.showModalCancel = true
    },

    getStatusClass(index) {
      // eslint-disable-next-line no-nested-ternary
      return this.hasConcluded ? 'status-concluido' : (index === this.lastNonCompletedIndex ? 'status-atual' : 'status-concluido')
    },
    isLast(item) {
      return this.dataHistory.indexOf(item) === this.dataHistory.length - 1
    },
    async handleGetStatusHistory() {
      if (!this.data?.id) {
        return
      }

      this.loadingHistory = true

      try {
        const infos = await axiosIns.get(`api/v1/personnel_request/employee_request_status_history/edit/${this.data.id}`)

        this.dataHistory = infos.data.data
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao requisitar os dados!',
          showConfirmButton: false,
          text: error,
          timer: 3000,
        })
      } finally {
        this.loadingHistory = false
      }
    },

    async handleFinishRequest() {
      this.loadingFinish = true

      const body = {
        status: 'CONCLUÍDO',
      }

      try {
        await axiosIns.put(`api/v1/personnel_request/employee_request/update/${this.data.id}`, body)

        this.$swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Requisição conluída',
          showConfirmButton: false,
          timer: 3000,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao enviar dados!',
          showConfirmButton: false,
          text: error,
          timer: 3000,
        })
      } finally {
        this.loadingFinish = false
        this.updatedTable()
        this.closeModal()
      }
    },

    handleGetCurrentDate() {
      const date = new Date()
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')

      const dataFormatada = `${year}-${month}-${day}`

      this.currentDate = dataFormatada
    },

    handleOpenModalSendDP() {
      this.showModalDP = true
    },

    async handleSendDirector() {
      this.loadingSubmitRequestSendDirector = true

      const body = {
        status: this.data.status === 'AGUARDANDO DIRETORIA' ? 'EM ANDAMENTO' : 'AGUARDANDO DIRETORIA',
      }

      if (this.data.status === 'AGUARDANDO DIRETORIA') body.director_approved_at = this.currentDate
      if (this.data.status === 'AGUARDANDO RD') body.manager_approved_at = this.currentDate

      try {
        await axiosIns.put(`api/v1/personnel_request/employee_request/update/${this.data.id}`, body)

        this.$swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Enviado para diretoria com sucesso!',
          showConfirmButton: false,
          timer: 3000,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao enviar dados!',
          showConfirmButton: false,
          text: error,
          timer: 3000,
        })
      } finally {
        this.loadingSubmitRequestSendDirector = false
        this.updatedTable()
        this.closeModal()
      }
    },
    async handleCancelRequest() {
      this.loadingCancel = true

      const body = {
        status: 'CANCELADO',
      }

      try {
        await axiosIns.put(`api/v1/personnel_request/employee_request/update/${this.data.id}`, body)

        this.$swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Requisição recusada com sucesso!',
          showConfirmButton: false,
          timer: 3000,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao enviar dados!',
          showConfirmButton: false,
          text: error,
          timer: 3000,
        })
      } finally {
        this.loadingCancel = false
        this.updatedTable()
        this.closeModal()
      }
    },
  },
}
</script>
<style scoped>
.head-modal{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.separator{
  position: relative;
  border-top: 1px solid #cac9cd96;
  margin-top: 20px;
}

.separator .label{
  top: -22px;
  left: 50px;
  position: absolute;
  z-index: 100;
  background: #312d4b;
  padding: 8px;
}

.line{
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  margin-top: 30px;
}

.container-center{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.validations{
  position: relative;
  width: 100%;
  padding: 12px;
  border: 1px solid #cac9cd4f;
  border-radius: 5px;
}

.validations span{
  position: absolute;
  top: -22px;
  left: 10px;
  background: #312d4b;
  padding: 8px;
}

.infos{
  display: flex;
}

.image{

  display: flex;
  align-items: center;
  justify-content: center;
}

.tag{
  background: #354546;
  color: #35ab14;
  width: 85px;
  padding: 3px;
  font-size: 10px;
  margin-left: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px
}

.infos-text{
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-container{
  position: relative;
  width: 100%;
  padding: 12px;
  border: 1px solid #cac9cd4f;
  border-radius: 5px;
  margin-top: 30px;
}

.progress-container-loading{
  position: relative;
  width: 100%;
  padding: 12px;
  border: 1px solid #cac9cd4f;
  border-radius: 5px;
  margin-top: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

}

.progress-container span{
  position: absolute;
  top: -22px;
  left: 10px;
  background: #312d4b;
  padding: 12px;
  font-size: 12px;
}

.item-time-line{
  border-left: 1px dashed #8C8C8C;
  padding: 10px;
  margin-left: 9px;

  p{
    font-size: 12px;
  }
}

.container-item{
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  & + &{
    margin-top: 0px;

  }
}

.item-time-line-waiting{
  padding: 10px;
  margin-left: 9px;

  p{
    font-size: 12px;
  }
}

.container-icon-waiting{
  display: flex;

  small{
    font-size: 12px;
    margin-left: 8px;
    color: #FFB270;
  }
}

.container-icon{
  display: flex;

  small{
    font-size: 12px;
    margin-left: 8px;
  }

  .status-atual {
    color: #FFB270;
  }

  .status-concluido {
    color: #39CE2F;
  }
}

.stand-by{
  display: flex;
  align-items: center;

  p{
    margin-top: 12px;
  }
}

.footer{
  display: flex;
  justify-content: center;
}

.inputs-approved{
  margin-top: 20px;
}

.wrapper-inputs{
  display: flex;
  gap: 10px;
}

.actions-finish{
  display: flex;
  align-items: center;
  justify-content: center;
}

.infos-aproved{
  margin-left: 10px;
}
.tabs-just{
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>
