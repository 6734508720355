<template>
  <v-skeleton-loader
    v-if="skeleton"
    type="article, actions"
  ></v-skeleton-loader>
  <div v-else>
    <v-card class="px-9 py-9">
      <div class="head-modal">
        <div></div>

        <span>Requisição de Pessoal</span>

        <v-icon
          medium
          class="me-2"
          @click="handleCloseModalEdit"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>

      <div class="py-4">
        <span>👋 Olá, para requisitar, basta preencher os campos abaixo!</span>
      </div>

      <div class="separator">
        <div
          class="label"
        >
          <span>Justificativa</span>
        </div>
      </div>

      <div class="line">
        <v-autocomplete
          v-model="typeContractValue"
          class=""
          :items="listTypeContract"
          label="Tipo de Contrato"
          outlined
          dense
          disabled
        />

        <v-autocomplete
          v-model="reasonRequestValue"
          class=""
          :items="listReasonRequest"
          label="Motivo da Requisição"
          outlined
          dense
          disabled
        />
      </div>

      <v-autocomplete
        v-model="replacedEmployeeValue"
        class=""
        :items="listEmployees"
        label="Funcionário Substituído"
        item-text="name"
        item-value="id"
        outlined
        dense
        :disabled="ReasonRequestValue !== 'Substituição'"
      />

      <v-textarea
        v-model="reasonDescriptionValue"
        label="Descrição do motivo"
        outlined
        disabled
      />

      <div class="separator">
        <div
          class="label"
        >
          <span>Dados da vaga</span>
        </div>
      </div>

      <v-text-field
        v-model="functionValue.name"
        class="mt-5"
        label="Função"
        item-text="name"
        item-value="id"
        outlined
        dense
        disabled
      />

      <v-text-field
        v-model="sectorValue.name"
        class=""
        label="Setor"
        item-text="name"
        item-value="id"
        outlined
        dense
        disabled
      />

      <div class="line mt-0">
        <v-text-field
          v-model="ageValue"
          class=""
          label="Idade"
          outlined
          dense
          disabled
        />

        <v-autocomplete
          v-model="genderValue"
          class=""
          :items="listGenders"
          item-text="name"
          item-value="id"
          label="Sexo"
          outlined
          dense
        />
        <v-autocomplete
          v-model="educationLevelValue"
          class=""
          :items="listEducation"
          item-text="name"
          item-value="id"
          label="Nível de escolaridade"
          outlined
          dense
        />
      </div>

      <v-textarea
        v-model="requirementsVacancyValue"
        label="Requisitos da Vaga"
        outlined
      />

      <v-textarea
        v-model="mainActivitiesValue"
        label="Principais Atividades"
        outlined
      />

      <div
        v-if="profile === 'Recursos Humanos'"
        class="container-center"
      >
        <v-btn
          color="error"
          class="ml-4"
          @click="handleOpenModalCancel"
        >
          CANCELAR
        </v-btn>

        <v-btn
          color="success"
          class="ml-4"
          @click="handleOpenModalSendRD"
        >
          ENVIAR PARA RD
        </v-btn>
      </div>
    </v-card>

    <v-dialog
      v-model="showModalCancel"
      width="540px"
    >
      <ModalCancel
        :data="dataCancel"
        @close="showModalCancel = false"
        @updatedTable="handleUpdatedTable"
      ></ModalCancel>
    </v-dialog>

    <v-dialog
      v-model="showModalSendRD"
      width="540px"
    >
      <ModalSendRD
        :data="dataModalSendRD"
        :close-modal-edit="handleCloseModalEditData"
        @close="showModalSendRD = false"
        @updatedTable="handleUpdatedTable"
      ></ModalSendRD>
    </v-dialog>
  </div>
</template>
<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import { mdiClose } from '@mdi/js'
import localStorageSlim from 'localstorage-slim'
import ModalCancel from './ModalCancel.vue'

import ModalSendRD from './ModalSendRD.vue'

export default {
  components: {
    ModalCancel,
    ModalSendRD,
  },
  mixins: [formatters],

  props: {
    data: {
      type: [String, Object],
      required: true,
    },
    closeModalEdit: { type: Function, default: () => {} },
    profile: { type: String, default: '' },
  },

  data() {
    return {
      dataModalSendRD: {},
      loadingSubmitRequest: false,

      listGenders: ['MASCULINO', 'FEMININO', 'NÃO BINÁRIO'],
      listEducation: ['FUNDAMENTAL', 'MÉDIO', 'SUPERIOR', 'SUPERIOR INCOMPLETO'],

      typeContractValue: '',
      reasonRequestValue: '',
      replacedEmployeeValue: {},
      reasonDescriptionValue: '',
      functionValue: {},
      sectorValue: '',
      ageValue: '',
      educationLevelValue: '',
      genderValue: '',
      requirementsVacancyValue: '',
      mainActivitiesValue: '',

      listEmployees: [],
      listFunctions: [],
      listSectors: [],

      skeleton: true,
      showModalCancel: false,
      showModalSendRD: false,
      icons: {
        mdiClose,
      },
      listTypeContract: [
        'CONTRATO EFETIVO',
        'CONTRATO TEMPORÁRIO',
        'CONTRATO POR TEMPO DETERMINADO',
        'ESTAGIÁRIO',
        'APRENDIZ',
      ],
      listReasonRequest: [
        'AUMENTO DE QUADRO',
        'PROMOÇÃO',
        'SUBSTITUIÇÃO',
      ],
      ReasonRequestValue: '',
      dataCancel: {},
    }
  },

  async created() {
    this.dataCancel = this.data

    if (!this.data?.id) {
      this.handleCloseModalEdit()

      return
    }

    try {
      const response = await axiosIns.get(`api/v1/personnel_request/employee_request/edit/${this.data.id}`)

      this.typeContractValue = response.data.data.contract_types
      this.reasonRequestValue = response.data.data.request_reason
      this.replacedEmployeeValue = { name: response.data.data?.substitute_user?.name, id: response.data.data?.substitute_user?.id }
      this.reasonDescriptionValue = response.data.data.request_reason_description
      this.functionValue = { name: response.data.data?.occupation?.name, id: response.data.data?.occupation?.id }
      this.sectorValue = { name: response.data.data?.sector?.name, id: response.data.data?.sector?.id }
      this.ageValue = response.data.data.age
      this.educationLevelValue = response.data.data.education_level
      this.genderValue = response.data.data.sex
      this.requirementsVacancyValue = response.data.data.vacancy_requirements
      this.mainActivitiesValue = response.data.data.main_activities
    } catch (error) {
      this.$swal({
        icon: 'error',
        title: 'Erro!',
        showConfirmButton: false,
        text: 'Erro ao buscar os dados na API!',
        timer: 3000,
      })
    } finally {
      this.skeleton = false
    }

    const listEmployees = localStorageSlim.get('listEmployees', { decrypt: true })

    this.listEmployees = listEmployees

    axiosIns.get('api/v1/records/occupation/index').then(res => {
      this.listFunctions = res.data.data
    })

    axiosIns.get('api/v1/records/sector/index').then(res => {
      this.listSectors = res.data.data
    })
  },

  methods: {
    handleCloseModalEdit() {
      this.closeModal()
    },

    handleUpdatedTable() {
      this.updatedTable()
    },

    handleCloseModalEditData() {
      this.closeModalEdit()
    },

    handleOpenModalCancel() {
      this.showModalCancel = true
    },

    async handleOpenModalSendRD() {
      const body = {
        contract_types: this.typeContractValue,
        request_reason: this.reasonRequestValue,
        occupation_id: this.functionValue,
        sector_id: this.sectorValue,
        age: Number(this.ageValue),
        sex: this.genderValue,
        education_level: this.educationLevelValue,
        vacancy_requirements: this.requirementsVacancyValue,
        main_activities: this.mainActivitiesValue,
        status: 'AGUARDANDO RD',
        substitute_user_id: this.replacedEmployeeValue,
        request_reason_description: this.reasonDescriptionValue,
        id: this.data.id,
      }

      this.dataModalSendRD = body

      this.showModalSendRD = true
    },
  },
}
</script>
<style scoped>
.head-modal{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.separator{
  position: relative;
  border-top: 1px solid #cac9cd96;
  margin-top: 20px;
}

.separator .label{
  top: -22px;
  left: 50px;
  position: absolute;
  z-index: 100;
  background: #312d4b;
  padding: 8px;
}

.line{
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  margin-top: 30px;
}

.container-center{
  display: flex;
  justify-content: center;
}
</style>
