<template>
  <div>
    <v-card class="px-9 py-9">
      <div class="head-modal">
        <div></div>

        <span>Enviar para DP</span>

        <v-icon
          medium
          class="me-2"
          @click="handleCloseModalCanceled"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>

      <div class="py-4">
        <span>👋 Olá, antes de enviar para o DP, preencha os campos</span>
      </div>

      <div class="separator">
        <div
          class="label"
        >
          <span>Informações</span>
        </div>
      </div>

      <v-textarea
        v-model="leaderObservations"
        class="mt-6"
        label="Parecer do Requisitante"
        outlined
      />

      <div class="container-center">
        <v-btn
          color="success"
          class="ml-4"
          @click="handleOpenModalCancelConfirm"
        >
          <div v-if="!loadingCancel">
            <span>ENVIAR PARA DP</span>
          </div>

          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card>

    <v-dialog
      v-model="showModalCancelConfirm"
      width="400px"
    >
      <ModalCancelConfirm
        @close="showModalCancelConfirm = false"
      ></ModalCancelConfirm>
    </v-dialog>
  </div>
</template>
<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import utilsMixin from '@/plugins/utilsMixin'
import { mdiClose } from '@mdi/js'
import ModalCancelConfirm from './ModalCancelConfirm.vue'

export default {
  components: {
    ModalCancelConfirm,
  },
  mixins: [formatters, utilsMixin],

  props: {
    data: {
      type: [String, Object],
      required: true,
    },
  },

  data() {
    return {
      loadingCancel: false,
      showModalCancelConfirm: false,
      cancellationDescription: '',
      leaderObservations: '',
      icons: {
        mdiClose,
      },
    }
  },

  created() {
    console.log('enviando para DP: ', this.data)
  },

  methods: {
    handleCloseModalCanceled() {
      this.closeModal()
    },

    async handleOpenModalCancelConfirm() {
      this.loadingCancel = true

      console.log(this.data)

      const body = {
        status: 'AGUARDANDO DP',
        leader_observations: this.leaderObservations,
      }

      try {
        await axiosIns.put(`api/v1/personnel_request/employee_request/update/${this.data.id}`, body)

        this.$swal({
          icon: 'success',
          title: 'Requisição enviada para o DP!',
          showConfirmButton: false,
          timer: 3000,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao Enviar requisição!',
          showConfirmButton: false,
          text: error,
          timer: 3000,
        })
      } finally {
        this.loadingCancel = false

        this.updatedTable()
        this.closeModal()
      }
    },
  },
}
</script>
<style scoped>
.head-modal{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.separator{
  position: relative;
  border-top: 1px solid #cac9cd96;
  margin-top: 20px;
}

.separator .label{
  top: -22px;
  left: 50px;
  position: absolute;
  z-index: 100;
  background: #312d4b;
  padding: 8px;
}

.container-center{
  display: flex;
  justify-content: center;
}
</style>
